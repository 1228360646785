<template>
  <div class="paper">
    <div class="tree">
      <div class="head">
        <h3 v-if="!isPaper">{{ data.title }}</h3>
        <ul :class="{tabs: true, 'mobile-tabs': $device.isMobileOrTablet}" v-if="isPaper">
          <li class="slide-block" :style="{left: activeStyle.left}"></li>
          <li v-for="(item, index) in menus" :style="getLiStyle(index)">
            <NuxtLink :to="linkTo(`/sjlb${item.url}${id}`)">{{ item.name }}</NuxtLink>
          </li>
        </ul>
      </div>

      <ul v-if="id !== '0' && data?.list?.length > 0" class="parent-box">
        <li v-for="item in data.list" :key="item.id">
          <NuxtLink :to="linkTo(item)">
          <div class="title">
            <div class="left-box">
              <div class="title-box">
                <img src="https://static.ppkao.com/www/images/icon-topic.png" :alt="item.title ">
                <h2>{{ item.title }}</h2>
              </div>
              <span class="sub-info" v-if="item.kssj">考试时间：{{ item.kssj }}分钟 <span>总分：{{ item.sjzf }}分</span></span>
            </div>
            <button class="look-btn" v-wave>查看答案</button>
          </div>
          </NuxtLink>
          </li>
        </ul>
      <Empty v-else :title="`${isPaper ? '试卷':'题目'}正在补充中噢~`"></Empty>
      <div class="page-box" v-if="data?.totalcount">
        <Pagination
          :total="data.totalcount"
          :page-size="data.pagesize"
        />
      </div>
    </div>
    <ClientOnly>
      <LazyTikuSideBar v-if="$device.isDesktop"/>
    </ClientOnly>

  </div>
</template>

<script setup>
import { getDayList, getSjList, getZjList } from '~/api';
import { useSeo } from '../../composables/useSeo.js'
import { linkTo } from '../../utils/index.js'

const activeIndex = computed(() => {
  if (type === 'yati') return 3;
  else if (type === 'moniti') return 2;
  else if (type === 'zhenti') return 1;
  return 0
}) // 当前激活的菜单索引

const activeStyle = computed(() => {
  return { left: `${ activeIndex.value * 25 }%`, color: '#855E30' }
});
const route = useRoute()
const { type, id, page } = route.params;
const { name } = route.meta;
const isPaper = computed(() => name === 'paper');
let data = ref();
//  '历年真题', '模拟试题', '押题密卷'
const menus = [
  {name: '全部试卷', url: '/'}, {name: '历年真题', url: '/zhenti/'},
  {name: '模拟试题', url: '/moniti/'},{name: '押题密卷', url: '/yati/'}
]; // 试卷菜单类型

const getLiStyle = (index) => {
  return {
    fontWeight: activeIndex.value === index ? 'bold' : '500',
  };
};

if (id !== '0' && id) {
  switch (name) {
    case 'paper':
      data = await getSjList({tid: id, sjtype: type, page});
      data.value.list = data.value.sjlist ?? [];
      break;
    case 'chapter':
      data = await getZjList({tid: id, page});
      data.value.list = data.value.kstkzjlist ?? [];
      break;
    case 'day':
      data = await getDayList({tid: id, page});
      data.value.list = data.value.kstkdaylist ?? [];
      break;
  }
  useSeo(data.value)
}

</script>
<style>
:root {
  --mnkc-border: #FFE8C8;
  --mnkc-btn-color: #855E30;
  --mnkc-gradient-bg: linear-gradient(90deg, #EEC284, #EDDABF);
}
html.dark {
  --mnkc-border: #39394E;
  --mnkc-btn-color: #f5f6f9;
  --mnkc-gradient-bg: linear-gradient(90deg, #5F5F6C, #575773);
}
</style>
<style scoped lang="scss">
@use "@/assets/styles/collpase.scss" as *;
.page-box {
  @include flex-x-center;
  margin: var(--pagination-margin);
  width: 100%;
  align-self: flex-start;
}
.sub-info {
  margin-top: 12px;
  margin-left: 26px;
  @include font(var(--tab-color), var(--search-tool-font));
  span {
    margin-left: 20px;
  }
}
.paper {
  max-width: var(--web-wdith);
  margin: 30px auto 0;
  @include flex-bet;
  .tree {
    flex: 1;
    padding: 0 var(--card-padding);
    background: var(--sj-list-bg);
    border-radius: 8px;
    position: relative;
    align-self: flex-start;
    .head {
      padding: var(--search-box-mt) 0 20px 0;
      .tabs {
        @include flex-y-center;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid var(--mnkc-border);
        background-color: var(--card-bg-color);
        width: fit-content;
        position: relative;
        li {
          width: 124px;
          height: 48px;
          @include font(var(--mnkc-btn-color), 16px, 500);
          @include flex-center;
          z-index: 2;
          a {
            @include flex-center;
            width: 100%;
            height: 100%;
          }
        }
        .slide-block {
          border-radius: 8px;
          background: var(--mnkc-gradient-bg);
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          transition: left .3s;
        }
      }
      .mobile-tabs {
        width: 100%;
        li {
          flex: 1;
          width: auto;
        }
        .slide-block {
          width: 25%;
        }
      }
      h3 {
        @include font(var(--title-color), 20px, 600);
        margin-bottom: var(--h3-mt);
      }
    }
    .title-box h2 {
      font-weight: normal;
      margin-left: 10px;
    }
  }
}

</style>
